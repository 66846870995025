import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/seo"

const Bsp = () => {
  return (
    <Layout>
      <Seo title="BSP" />
      <h1 className="post-title">Bernský salašnický pes</h1>
      <h3>O plemeni</h3>
      <p>
        Bernský salašnický pes je jedním ze zástupců švýcarských plemen. Ve své domovině byl vyšlechtěn jako univerzální farmářský pes, který svému majiteli pomáhal s hlídáním a naháněním stád ovcí a dobytka, s taháním vozíků a břemen a v neposlední řadě mu byl dobrým a věrným společníkem. Dnes se se ním setkáváme především jako s oblíbeným rodinným psem. Jeho hlavními přednostmi je atraktivní vzhled a vyrovnaná povaha. Bernský salašnický pes není vhodný k chovu v bytě a nejlépe mu proto bude u rodinného domku se zahradou v malém městě nebo na venkově. Přestože je bernský salašnický pes běžně rozšířeným plemenem, má poměrně malý genetický fond, což je příčinou většího výskytu dědičných chorob.
      </p>
    </Layout>
  )
}

export default Bsp
